import React from 'react';
import {Dropdown} from 'react-bootstrap';
import Logo from '../../assets/images/logo_virbac.svg';
import Slogan from '../../assets/images/slogan.svg';
import HeaderBG from '../../assets/images/header_bg.jpg';
import {useVirbac} from "../../VirbacProvider";
import {NavLink} from "react-router-dom";
import cogoToast from "cogo-toast";

const toastoptions = {
   hideAfter: 5,
   position: 'top-right'
}

const Navbar = (props) => {
   const virbac = useVirbac();

   return <nav
      style={{backgroundImage: "url(" + HeaderBG + ")"}}
      className="navbar">
      <div style={{backgroundImage: "url(" + Slogan + "), linear-gradient(90deg, rgba(255,255,255,0) 40%, rgba(255,255,255,1) 100%)"}} className="header-container">
         <img src={Logo} alt={"logo-virbac"}/>
         {virbac.utilizador &&
         <div className={"header-panels" + (virbac.utilizador.Tipo === "admin" ? " responsive" : "")}>
            <div className={"d-flex"}>
               <NavLink to="/dashboard" activeStyle={{backgroundColor: '#f3f4fa'}} className="header-panel">
                  Painel Inicial
               </NavLink>
               {virbac.utilizador.Tipo === "delegado" &&
               <>
                  <NavLink to="/produtos" activeStyle={{backgroundColor: '#f3f4fa'}} className="header-panel ml-3">
                     Produtos
                  </NavLink>
               </>}
               {virbac.utilizador.Tipo === "admin" &&
               <>
                  <NavLink to="/clientes" activeStyle={{backgroundColor: '#f3f4fa'}} className="header-panel ml-3">
                     Clientes
                  </NavLink>
                  <NavLink to="/clinicas" activeStyle={{backgroundColor: '#f3f4fa'}} className="header-panel ml-3">
                     Clínicas
                  </NavLink>
                  <NavLink to="/delegados" activeStyle={{backgroundColor: '#f3f4fa'}} className="header-panel ml-3">
                     Delegados
                  </NavLink>
                  <NavLink to="/produtos" activeStyle={{backgroundColor: '#f3f4fa'}} className="header-panel ml-3">
                     Produtos
                  </NavLink>
                  <NavLink to="/marketing" activeStyle={{backgroundColor: '#f3f4fa'}} className="header-panel ml-3">
                     Marketing
                  </NavLink>
               </>
               }
            </div>

            <Dropdown>
               <Dropdown.Toggle className="nav-link bg-transparent">
                  {virbac.utilizador && virbac.utilizador.Nome &&
                  <p className="primary-color mb-1 text-right"><strong>{virbac.utilizador.Nome} {virbac.utilizador.Apelido}</strong><br/>
                     <span className={"text-capitalize"} style={{fontSize: 10}}>{virbac.utilizador.Tipo}</span></p>
                  }
               </Dropdown.Toggle>
               <Dropdown.Menu className="preview-list navbar-dropdown pb-3">
                  <Dropdown.Item className="dropdown-item preview-item d-flex align-items-center border-0"
                                 onClick={() => {
                                    virbac.logout();
                                    cogoToast.success('Fez logout com sucesso.', toastoptions);
                                 }}>
                     Terminar Sessão
                  </Dropdown.Item>
               </Dropdown.Menu>
            </Dropdown>
         </div>
         }
      </div>
   </nav>;
}

export default Navbar;
