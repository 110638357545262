import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import {Search} from 'react-bootstrap-table2-toolkit';
import DatePicker from 'react-datepicker';
import {Dropdown} from "react-bootstrap";
import moment from "moment";

import './styles.scss';

const {SearchBar} = Search;

const defaultSorted = [{
   dataField: 'id',
   order: 'desc'
}];

const pageButtonRenderer = ({page, active, disabled, title, onPageChange}) => {
   const handleClick = (e) => {
      e.preventDefault();
      onPageChange(page);
   };

   return <li className="page-item">
      <button type="button" className={"btn outlined" + (active ? " active" : "")}
              onClick={handleClick}>{page}</button>
   </li>;
};


const options = {
   pageButtonRenderer,
   alwaysShowAllBtns: true,
   withFirstAndLast: false,
   hideSizePerPage: true,
   prePageText: "Anterior",
   nextPageText: "Seguinte",
};

const DataTable = ({data, columns, title, showSearchBar, exportUrl, showNewButton, ...props}) => {

   return <div className={"DataTable card " + props.className}>
      <div className="card-padding">
         <div>
            <div className="d-flex mb-4 justify-content-between buttons-table">
               <p className={"font-weight-bold table-title primary-color mr-3 flex-grow-1"}>{title}</p>
               {props.enableRange && props.range && props.onRangeChange && <div className={"range"}>
                  <span className={"mr-3"}>De <DatePicker dateFormat="dd/MM/yyyy" placeholderText={"01/01/2000"} selected={props.range[0]} onChange={(value) => props.onRangeChange(value, props.range[1])} className="form-control form-control-sm ml-1"/></span>
                  <span className={"mr-3"}>Até <DatePicker dateFormat="dd/MM/yyyy" placeholderText={moment().format("DD/MM/yyyy")} selected={props.range[1]} onChange={(value) => props.onRangeChange(props.range[0], value)} className="form-control form-control-sm ml-1"/></span>
               </div>}
               {showSearchBar &&
               <label className="search-label">
                  <input type="text" className="form-control form-control-sm flex-grow-1" placeholder={props.searchPlaceholder} onChange={(e) => props.onSearchChange && props.onSearchChange(e.target.value)}/>
               </label>}
               {showNewButton &&
               <button type="button" className="btn outlined ml-3" onClick={props.newOnClick}>{props.newButtonName}</button>
               }
               {props.extraActions && props.extraActions.map(acao => {
                  return <button type="button" className="btn outlined ml-3" onClick={acao.action}>{acao.label}</button>
               })}
               {exportUrl &&
               <Dropdown>
                  <Dropdown.Toggle className={"ml-3 export-btn"}>Exportar</Dropdown.Toggle>
                  <Dropdown.Menu className="m-0">
                     <Dropdown.Item href={exportUrl} target="_blank">Excel</Dropdown.Item>
                  </Dropdown.Menu>
               </Dropdown>
               }
            </div>

            <BootstrapTable
               remote
               bootstrap4
               keyField={"id"}
               defaultSorted={defaultSorted}
               pagination={props.paginationOptions ? paginationFactory({...options, ...props.paginationOptions}) : null}
               data={data}
               columns={columns}
               wrapperClasses="table-responsive"
               bodyClasses="primary-color"
               bordered={false}
               onTableChange={(type, {page}) => {
                  if (type === "pagination")
                     props.onPageChange(page);
               }}
               rowClasses={props.rowClasses}
               header
            />
         </div>
      </div>
   </div>
}

export default DataTable;
