import React from "react";
import {useVirbac} from "../../../../VirbacProvider";

export default ({register, errors}) => {
   const virbac = useVirbac();

   return <div>
      <div className="row">
         <div className="col-md-6">
            <div className="form-group">
               <label data-error={errors.DesignacaoSocial?.message}>Designação Social*
                  <input ref={register({
                     required: "Por favor indique a designação social"
                  })} name="DesignacaoSocial" placeholder="Designação Social" type="text" className="form-control form-control-sm"/>
               </label>
            </div>
         </div>
         <div className="col-md-6">
            <div className="form-group">
               <label data-error={errors.NomeComercial?.message}>Nome Comercial
                  <input ref={register} name="NomeComercial" placeholder="Nome Comercial" type="text" className="form-control form-control-sm"/>
               </label>
            </div>
         </div>
      </div>
      <div className="form-group">
         <label data-error={errors.Morada?.message}>Morada*
            <input ref={register({
               required: "Por favor indique a morada"
            })} name="Morada" placeholder="Morada" type="text" className="form-control form-control-sm"/>
         </label>
      </div>
      <div className="row">
         <div className="col-md-5">
            <div className="form-group">
               <label data-error={errors.CodigoPostal?.message}>Código Postal*
                  <input ref={register({
                     required: "Por favor indique o código postal"
                  })} name="CodigoPostal" placeholder="Código Postal" type="text" className="form-control form-control-sm"/>
               </label>
            </div>
         </div>
         <div className="col-md-5">
            <div className="form-group">
               <label data-error={errors.Localidade?.message}>Localidade*
                  <input ref={register({
                     required: "Por favor indique a localidade"
                  })} name="Localidade" placeholder="Localidade" type="text" className="form-control form-control-sm"/>
               </label>
            </div>
         </div>
      </div>
      <div className="row">
         <div className="col-md-5">
            <div className="form-group">
               <label data-error={errors.Telefone?.message}>Telefone*
                  <input ref={register({
                     required: "Por favor indique o telefone"
                  })} name="Telefone" placeholder="Telefone" type="text" className="form-control form-control-sm"/>
               </label>
            </div>
         </div>
         <div className="col-md-5">
            <div className="form-group">
               <label data-error={errors.Contribuinte?.message}>NIF*
                  <input ref={register({
                     required: "Por favor indique o NIF"
                  })} name="Contribuinte" placeholder="NIF" type="text" className="form-control form-control-sm"/>
               </label>
            </div>
         </div>
         <div className="col-md-5">
            <div className="form-group">
               <label data-error={errors.Email?.message}>Email*
                  <input ref={register({
                     required: "Por favor indique o email",
                     pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: "O email não é válido"
                     }
                  })} name="Email" placeholder="Email" type="email" className="form-control form-control-sm"/>
               </label>
            </div>
         </div>
         {virbac.utilizador.Tipo === "admin" && <div className={"col-md-5 mt-2"}>
            <div className="form-check mb-0">
               <label className="form-check-label label-checkbox" data-error={errors.Ativa?.message}>
                  <input name="Ativa" type="checkbox" className="form-check-input"
                         ref={register}/>
                  <i className="input-helper"></i>
                  Clínica Ativa
               </label>
            </div>
         </div>}
      </div>
   </div>;
}