import React, {useEffect, useState} from 'react';
import DataTable from "../DataTable";
import {get, post} from "../../VirbacProvider";
import {useHistory} from "react-router-dom";
import {useFormulario} from "../Formularios/Provider";
import {confirmAlert} from "react-confirm-alert";
import cogoToast from 'cogo-toast';

import Editar from "../../assets/images/editar_amarelo.svg";
import Eliminar from "../../assets/images/icone_eliminar.svg";
import Visualizar from "../../assets/images/visualizar_icon.svg";


const TabelaClinicas = (props) => {
   const numero_resultados = props.numero_resultados || 6;
   const formulario = useFormulario();
   const history = useHistory();

   const [clinicas, setClinicas] = useState(null);
   const [pagina, setPagina] = useState(0);
   const [pesquisa, setPesquisa] = useState("");

   const obterClinicas = async () => {
      let clinicas = await get("/clinica/listar", {
         pagina,
         numero_resultados,
         pesquisa
      });
      setClinicas(clinicas);
   };

   useEffect(() => {
      obterClinicas();
   }, [pagina, pesquisa]);

   if (!clinicas)
      return null;

   let columns = (colunas_apresentar) => {
      let columns = [
         {
            key: "contribuinte",
            dataField: 'Contribuinte',
            text: "NIF",
            sort: true,
            headerStyle: {
               color: '#001737',
               fontWeight: '700',
               border: 'none'
            }
         }, {
            key: "nome_comercial",
            dataField: 'NomeComercial',
            text: 'Nome Comercial',
            sort: true,
            headerStyle: {
               color: '#001737',
               fontWeight: '700',
               border: 'none'
            }
         }, {
            key: "localidade",
            text: 'Localidade',
            sort: true,
            headerStyle: {
               color: '#001737',
               fontWeight: '700',
               border: 'none'
            },
            formatter: (_, clinica) => {
               return (
                  <p className={"m-0"}>{clinica.CodigoPostal} {clinica.Localidade}</p>
               );
            }
         }, {
            key: "telefone",
            dataField: 'Telefone',
            text: 'Telefone',
            sort: true,
            headerStyle: {
               color: '#001737',
               fontWeight: '700',
               border: 'none'
            },
         }, {
            key: "email",
            dataField: 'Email',
            text: 'Email',
            sort: true,
            headerStyle: {
               color: '#001737',
               fontWeight: '700',
               border: 'none'
            },
            formatter: (email) => {
               return (
                  <a className={"primary-color underline"} href={`mailto:${email}`}>{email}</a>
               )
            }
         }, {
            key: "accoes",
            dataField: 'action',
            text: 'Ações',
            sort: false,
            headerStyle: {
               color: '#001737',
               fontWeight: '700',
               border: 'none'
            },
            formatter: (_, clinica) => {
               return (<div className={"d-flex"}>
                  <img className={"cursor-pointer mr-2"} onClick={() => history.push(`/clinicas/${clinica.id}`)} src={Visualizar}/>
                  <img className={"cursor-pointer mr-2"} src={Editar} alt={"botao editar clínica"} onClick={() => formulario.abrirFormulario("criar_clinica", {
                     valoresIniciais: clinica,
                     extra: {
                        acao: "editar",
                        IdClinica: clinica.id
                     },
                     formularioSubmetido: () => {
                        obterClinicas();
                     }
                  })}/>
                  <img className={"cursor-pointer"} src={Eliminar} alt={"botao eliminar delegado"} onClick={() => {
                     confirmAlert({
                        title: `Apagar Clínica?`,
                        message: `Confirma que pretende eliminar a clínica '${clinica.DesignacaoSocial}'? Não é possível eliminar clínicas que já tenham movimentos associados.`,
                        buttons: [
                           {
                              label: 'Sim',
                              onClick: async () => {
                                 try {
                                    await post("/clinica/apagar", {
                                       id: clinica.id
                                    });
                                    obterClinicas();
                                 } catch (e) {
                                    cogoToast.error('Ocorreu um erro, a clínica poderá já ter movimentos associados', {hideAfter: 5, position: 'top-right'});
                                 }
                              }
                           },
                           {label: 'Cancelar'}
                        ]
                     });
                  }}
                  />
               </div>);
            }
         }
      ];

      // Itera sobre as colunas desejadas e procura a configuração
      return colunas_apresentar.map(coluna_apresentar => {
         // Devolve coluna com o mesmo key
         return columns.find(c => {
            return c.key === coluna_apresentar;
         });
      });
   };

   return <div className={"TabelaClinicas"}>
      <DataTable
         data={clinicas.resultados}
         columns={columns(props.colunas)}
         paginationOptions={{
            page: pagina + 1,
            sizePerPage: numero_resultados,
            totalSize: clinicas.paginacao.total_resultados
         }}
         title={"Clínicas"}
         showSearchBar={true}
         searchPlaceholder={"Pesquisar clínica"}
         exportUrl={props.exportUrl}
         showNewButton={true}
         newButtonName={"Nova clínica"}
         newOnClick={() => formulario.abrirFormulario("criar_clinica", {
            valoresIniciais: {Ativa: true},
            formularioSubmetido: async (resultado) => {
               history.push(`/clinicas/${resultado.id}`)
            }
         })}
         onPageChange={page => setPagina(page - 1)}
         onSearchChange={setPesquisa}
         rowClasses={r => {
            return r.Ativa ? undefined : "disabled"
         }}
      />
   </div>
};

export default TabelaClinicas;